<template>
  <header class="header" role="banner">
    <router-link class="btn_home" @click.native="activeMenu(0)" to="/">
      <img src="~@/assets/img/img_logo.png" width="114" height="40"
           alt="DREAMPLUS" class="ico_logo"></router-link>
    <nav class="nav_list_wrap">
      <ul class="nav_list" role="tablist">
        <li v-for="(menu, index) in menus[activeMenuIndex]" :key="index" role="presentation" class="nav_item">
          <router-link
            role="tab"
            class="nav_tab"
            :to="menu.to"
            :aria-selected="index === matchedTopMenuIndex"
          >{{menu.title}}</router-link>
        </li>
      </ul>
    </nav>
    <div class="useful_box">
      <router-link
        v-if="$store.state.userInfo"
        to="/signout"
        tag="a" class="btn_logout"
      >Logout</router-link>
      <a href="javascript:void(0);"  class="event_link"
         @click="eventGo"
         v-show="activeMenuIndex === 0">EVENT + HALL</a>
      <!-- <a href="javascript:void(0);"  class="event_link event_link_wrapper"
         v-show="activeMenuIndex === 1">DREAMPLUS INTRANET
         <span class="event_link_dropdown">
          <span  class="event_link_dropdown_contents">
            <a href="javascript:void(0);"  class="event_link"
              @click="ganGnamGo"
              v-show="activeMenuIndex === 1">DREAMPLUS GANGNAM</a>
            <a href="javascript:void(0);"  class="event_link"
              @click="six3Go"
              v-show="activeMenuIndex === 1">DREAMPLUS 63</a>
          </span>
        </span> 
      </a> -->
      <a href="javascript:void(0);"  class="event_link"
        @click="ganGnamGo"
        v-show="activeMenuIndex === 1">DREAMPLUS GANGNAM</a>
      <div class="lang_area">
        <router-link
          to="/ko"
          :aria-selected="$i18n.locale === 'ko'"
          role="button" class="btn_lang"
        >KR</router-link>
        <router-link
          to="/en"
          :aria-selected="$i18n.locale === 'en'"
          role="button"
          class="btn_lang"
        >EN</router-link>
      </div>
    </div>
  </header>
</template>

<script>
/**
 * GNB
 */
export default {
  name: 'NewGlobalNavigationBar',
  props: {
    activeMenuIndex: Number,
  },
  data() {
    return {
      isMatched: {
        type: Boolean,
        default: false,
      },
      /**
       * 메뉴
       * @type {Array}
       */
      menus: [
        [
          {
            title: 'DREAMPLUS',
            to: {
              path: '/dreamplus',
            },
            matchCondition: ['/', /\/dreamplus/],
          },
          {
            title: 'STUDIO',
            to: {
              path: '/studio',
            },
            matchCondition: [/\/studio/],
          },
          {
            title: 'PROGRAMS',
            to: '/programs',
            matchCondition: [/\/programs\/programs/],
          },
          {
            title: 'NEWS',
            to: '/contents',
            matchCondition: [/\/contents/],
          },
          {
            title: 'APPLY',
            to: '/contest',
            matchCondition: [/\/contest/, /\/programs\/application/],
          },
        ],
        [
          {
            title: 'EVENT',
            to: {
              path: '/event/list',
            },
            matchCondition: [/\/event/],
          },
          {
            title: 'EVENT HALL RESERVATION',
            to: {
              path: '/reservation/intro',
            },
            matchCondition: [/\/reservation/],
          },
        ],
      ],
    };
  },

  computed: {
    /**
     * match된 상위메뉴 index (현재 URL에 match)
     */
    matchedTopMenuIndex() {
      const { path } = this.$route;
      const i = this.activeMenuIndex;
      return this.menus[i].findIndex((menu) => menu.matchCondition.some((c) => {
        switch (typeof c) {
          case 'string':
            return c === path;
          default:
            return c.test(path);
        }
      }));
    },
  },
  methods: {
    activeMenu(index) {
      this.$emit('activeMenu', index);
    },
    ganGnamGo() {
      window.open(`${process.env.VUE_APP_PORTAL}`);
    },
    six3Go() {
      window.open(`${process.env.VUE_APP_63}`);
    },
    eventGo() {
      window.open('/event');
    },
  },
};
</script>
